@font-face {
    font-family: 'Egon Sans';
    src: local('Egon Sans Regular'), local('Egon-Sans-Regular'),
        url('./EgonSans.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

@font-face {
  font-family: 'Egon Sans Light';
  src: local('Egon Sans Light'), local('Egon-Sans-Light'),
  url('./EgonSans-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
