/* You can add global styles to this file, and also import other style files */
@import "src/assets/vars.scss";
@import "./assets/fonts/egonSans.scss";

body {
  margin: 0;
}

p {
  margin: 0;
}

.page-wrapper {
  padding: 36px 48px;
}

a {
  color: $orange;
}

figcaption {
  opacity: 0.6;
}

@include Query($xs) {
  .page-wrapper {
    padding: 0;
  }
}
