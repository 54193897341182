//
$black: rgba(55, 71, 79,1.0);
$black-10: rgba(55, 71, 79, 0.1);

$white: rgb(238, 238, 238);
$white-80: rgb(238, 238, 238, 0.8);
$white-40: rgb(238, 238, 238, 0.4);
$white-20: rgb(238, 238, 238, 0.2);

$orange: rgba(255, 167, 38, 1.0);
