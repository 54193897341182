// MAX
$xs: "(max-width: 767px)";
$sm: "(max-width: 1023px)";
$md: "(max-width: 1279px)";
$lg: "(max-width: 1439px)";
// MIN
$xl: "(min-width: 1440px)";
$wide: "(min-width: 1696px)";
//LT
$lt-xs: "(min-width: 768px) and (max-width: 1023px)";

@mixin Query($media) {
  @media only screen and #{$media} {
    @content;
  }
}
